import React from "react"

import { Layout } from "../components/layout"
import { CrimsafeLogo } from "../components/images"
import { SEO } from "../components/seo"
import { Brochures } from "../components/brochures"

export default () => (
  <Layout>
    <SEO title="Crimsafe" meta={["crimsafe", "security"]} />
    <section>
      <h4>What is Crimsafe?</h4>
      <p>
        Crimsafe® is a stainless steel security mesh and frame system that is at the top of it's ﬁeld. It is used to secure doors, windows and outdoor
        areas from intruders and insects alike.
      </p>
      <p>
        From a distance, it looks like ﬂyscreen and it does the same job of keeping your house cool in Summer while keeping mosquitoes out. However,
        Crimsafe is unlike any other ﬂyscreen you've ever seen, because it makes your home virtually impenetrable to thieves.
      </p>
      <p>
        Crimsafe is the only security screen product in Australia where the mesh is screwed into the frame and clamped down with a patented device
        called Screw Clamp™. Every other security screen mesh sold in Australia is held in place with a wedge forced in under pressure, or simply
        glued in.
      </p>
      <a href="http://crimsafe.com.au" target="_blank">
        <CrimsafeLogo />
      </a>
      <img
        className="alignnone size-full wp-image-65"
        src="http://endeavourscreens.com.au/wp-content/uploads/2015/02/crimsafe_img_2.jpg"
        alt=""
        width="529"
      />
      <Brochures />
      {/* <h4>Brochure Download (PDF)</h4>
      <div className="browsers">
        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/CRIMSAFE_Ultimate_web_single_LR.pdf">Crimsafe Ultimate</a>
        </div>
        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/BROCHURE_Crimsafe_Commercial_systems.pdf">Commercial Systems</a>
        </div>
        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/BROCHURE_Crimsafe_Doors.pdf">Doors</a>
        </div>

        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/BROCHURE_Crimsafe_Fixed_windows.pdf">Fixed Windows</a>
        </div>
        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/BROCHURE_Crimsafe_Hinged_Safe-S-Cape_Brochure.pdf">Hinged Safe-S-Cape</a>
        </div>
        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/BROCHURE_Crimsafe_Single_Slider_Brochure_(August,_2013).pdf">Sliding Safe-S-Cape</a>
        </div>

        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/BROCHURE_Crimsafe_Cyclone_Brochure_.pdf">Cyclones</a>
        </div>
        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/CRIMSAFE_Ultimate_web_single_LR.pdf">Patio Enclosures</a>
        </div>
        <div className="col-sm-4 item-list">
          <a href="http://crimsafe.com.au/uploads/files/BROCHURE_CRIMSAFE_INSWING_SAFE-S-CAPE.pdf">Inswinging Safe-S-Cape</a>
        </div>
      </div> */}
      <h4>Massive Performance Difference</h4>
      <p>
        The difference in performance between a Crimsafe screen and an ordinary security mesh product is huge. Crimsafe actually repels the physical
        assault that a burglar or intruder would apply and Crimsafe resists attempts to cut through it like no other security screen.
      </p>
      <h4>5 more differences between Crimsafe and the rest of the "me too" products:</h4>
      <ol>
        <li>
          Crimsafe uses Structural Grade 304 stainless steel. Others use 316 Marine steel. 304 has a higher tensile strength than 316, meaning it can
          resist more force applied against it and is highly effective against knife attack. Uncoated Marine steel has a higher resistance to
          corrosion when immersed in salt-water, but Crimsafe specially developed black powdercoating gives Crimsafe 304 Structural Grade mesh rust
          resistance far beyond that of raw Marine steel.
        </li>
        <li>
          Crimsafe uses thicker mesh. You get more metal for your money. The strands of mesh in Crimsafe are 0.9mm thick. Others are 0.8mm. This means
          even though the difference cannot be seen with the naked eye, Crimsafe mesh is actually 26.5% thicker than the others and correspondingly
          stronger.
        </li>
        <li>
          You don't pay extra for a mid rail. A mid-rail through your security door reinforces it by stiffening the door frame, strengthens the
          resistance of the door's lock against a jemmy attack, protects the hinges of your door by reducing a criminal's ability to lever it from the
          wall and acts as a visual warning that prevents you from walking through an apparently open space. And they come standard with Crimsafe
          doors.
        </li>
        <li>
          Specially developed antistatic coating. The black coating of Crimsafe actively repels the build-up of dust and impurities that can make your
          screens look dirty. It was developed in conjunction with Dulux and makes Crimsafe the only commercial grade UV fade-resistant screen.
          Crimsafe also sell a specially developed anti-static glove that can be used to clean the mesh.
        </li>
        <li>
          Santoprene Frame Bead. The unique design of the insulating Frame Bead ensures that dirt and grime will not build up on the edge of the
          screen frame. The angled Santoprene rubber allows rainwater to wash away airborne particles that build up on the screen and stops it
          collecting on the top edge of the screen frame. Therefore eliminating any chance of the dissimilar metals being connected and in turn
          stopping electrolysis from occurring.
        </li>
      </ol>
      &nbsp;
      <h4>Testing by UNSW@ADFA Proves Crimsafe's Strength</h4>
      In 2009 the University Of New South Wales School Of Aerospace and Civil and Mechanical Engineering (now School of Engineering and Information
      Technology) at the Australian Defence Force Academy (UNSW@ADFA), at the request of Crimsafe, designed a rigorous scientiﬁc test to determine
      exactly how much force Crimsafe® screens can resist from an impact before breaking. Crimsafe also tested stainless steel security screens bought
      directly from 9 leading competitors. View Video Of the competing brands tested 6 failed to meet even the current minimum standard of impact
      resistance as required by the Australian Standard AS 5039—2008 and Methods of Testing AS 5041—2003. These screens are leading brands and many
      broke before withstanding even the basic requirement of 5 impacts of 100 joules of impact energy! The 2009 tests conducted at UNSW@ADFA clearly
      showed that Crimsafe security screens were by far the strongest of the products tested. Watch the video of the original 2009 tests below.
      <h4>2011 tests once again show Crimsafe's superior strength against the products tested.</h4>
      <img
        className="alignnone size-full wp-image-69"
        src="http://endeavourscreens.com.au/wp-content/uploads/2015/02/crimsafe_graph.jpg"
        alt=""
        width={585}
      />
      The above graph shows how Crimsafe screens out performed other competitor products tested. In 2011 Crimsafe repeated the tests using new samples
      from the same security screen companies plus an extra product from a company not included in the 2009 tests. Of the 10 competing products tested
      in 2011: 8 failed to reach the 200 joules level recommended by UNSW@ADFA 6 failed to reach the 100 joules level required by the Australian
      Standard Only 2 managed to pass the Australian Standard Crimsafe base product range, Crimsafe Regular, passed a minimum of 500 joules in a
      single impact! So - how strong are the security screens on your home? For more information on Crimsafe products please go to{" "}
      <a href="http://crimsafe.com.au" target="_blank">
        http://crimsafe.com.au
      </a>
    </section>
  </Layout>
)
