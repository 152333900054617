import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export const Brochures = () => {
  const data = useStaticQuery(graphql`
    {
      files: allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
      icon: file(relativePath: { eq: "download-icon.png" }) {
        childImageSharp {
          fixed(width: 55, height: 48) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  return (
    <div>
      <h4>Brochure Download (PDF)</h4>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {data.files.edges.map((file, index) => {
          return (
            <BackgroundImage
              Tag={"div"}
              key={`pdf-${index}`}
              style={{
                flex: 3,
                backgroundColor: "rgb(96, 96, 96)",
                backgroundSize: "auto auto",
                margin: "20px",
                width: "150px",
                height: "150px",
              }}
              fixed={data.icon.childImageSharp.fixed}
            >
              <Link to={file.node.publicURL} download>
                {file.node.name}
              </Link>
            </BackgroundImage>
          )
        })}
      </div>
    </div>
  )
}
